<template>
    <div>
      <div class="mx-auto px-6 sm:px-6 py-4 mt-4">
        <div class="py-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>
              <h2 class="text-2xl font-semibold leading-tight">{{titlePage}}</h2>
            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'Machines',params: {pageno:pageno,perpage: perPage,order:currentSortRoute,search:search} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
          <div class="shadow-md">
            <form @submit.prevent="validateBeforeSubmit" class="form" id="frm">
              <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-gray-400 rounded-t"></div>
              <div class="bg-white space-y-6">
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="owner">
                        Owner *
                      </label>
                      <div class="relative">
                        <select name="owner" id="owner" v-model="owner" v-validate="'required'" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('owner') }">
                          <option value=""></option>
                          <option v-for="item in owners" :value="item.owner">{{item.label}}</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('owner')">{{errors.first('owner')}}</p>
                    </div>
                   <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="company_code">
                        Company *
                      </label>
                      <div class="relative">
                        <select name="company_uic" v-model="company_uic" v-validate="'required'" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('company_uic') }">
                          <option value=""></option>
                          <option v-for="item in company" :value="item.uic">{{item.name}}</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('company_uic')">{{errors.first('company_uic')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="code">
                        Machine Name *
                      </label>
                      <input name="name" v-model="name" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('name') }" id="name" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('name')">{{errors.first('name')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="device_sn">
                        Device S/Number
                      </label>
                      <div class="relative">
                        <select name="device_sn" id="device_sn" v-model="device_sn" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('device_sn') }">
                          <option value=""></option>
                          <option v-for="item in serials" :value="item.serial">{{item.serial}}</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('device_sn')">{{errors.first('device_sn')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="device_id">
                        Device ID *
                      </label>
                       <input name="device_id" v-model="device_id" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('device_id') }" id="device_id" type="text" placeholder="">
                        <p class="text-red-600 text-xs italic" v-show="errors.has('device_id')">{{errors.first('device_id')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="machine_sn">
                        Machine S/N *
                      </label>
                      <input name="machine_sn" v-model="machine_sn" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('machine_sn') }" id="machine_sn" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('machine_sn')">{{errors.first('machine_sn')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="machine_type">
                        Machine Type
                      </label>
                      <div class="relative">
                        <select name="machine_type" id="machine_type" v-model="machine_type" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('machine_type') }">
                          <option value=""></option>
                          <option v-for="item in machinetype" :value="item.label">{{item.label}}</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('machine_type')">{{errors.first('machine_type')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="cf">
                        Alarm DB
                      </label>
                      <div class="relative">
                        <select name="alarmdb" id="alarmdb" v-model="alarmdb" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('alarmdb') }">
                          <option value=""></option>
                          <option v-for="item in alarms" :value="item.name">{{item.name}}</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('alarmdb')">{{errors.first('alarmdb')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="company_name">
                        Company Code
                      </label>
                      <input name="name" v-model="company_name" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('company_name') }" id="icompany_namea" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('iva')">{{errors.first('company_name')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="vpnlink">
                        VPN Link Online
                      </label>
                      <input name="name" v-model="vpnlink" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('vpnlink') }" id="vpnlink" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('vpnlink')">{{errors.first('vpnlink')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="vpnlink2">
                        VPN Link
                      </label>
                      <input name="vpnlink2" v-model="vpnlink2" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('vpnlink2') }" id="vpnlink2" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('vpnlink2')">{{errors.first('vpnlink2')}}</p>
                    </div>
                  </div>
                  <div class="pb-4">
                    <h4 class="font-semibold">Details</h4>
                    <hr>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="address">
                        Address
                      </label>
                      <input name="address" v-model="address" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('address') }" id="address" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('address')">{{errors.first('address')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="city">
                        City
                      </label>
                      <input name="city" v-model="city" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('city') }" id="city" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('city')">{{errors.first('city')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="cap">
                        Zip Code
                      </label>
                      <input name="name" v-model="cap" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('cap') }" id="cap" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('cap')">{{errors.first('cap')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="state">
                        Country
                      </label>
                      <input name="state" v-model="state" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('state') }" id="state" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('state')">{{errors.first('state')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="px-3 w-full">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="notes">
                        Note
                      </label>
                      <textarea name="notes" v-model="notes" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('notes') }" id="state" rows="5"></textarea>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('notes')">{{errors.first('notes')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>

            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'Machines',params: {pageno:pageno,perpage: perPage,order:currentSortRoute,search:search} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from 'qs';
import { AUTH_URL } from "actions/auth";
import { Validator } from 'vee-validate';

const dict = {
  custom: {
    owner: {
      required: 'Your owner is empty'
    },
    machine_sn: {
      required: () => 'Your serial number is empty'
    },
    uic: {
      required: 'Your company uic is empty'
    },
    name: {
      required: 'Your name is empty'
    }
  }
};

Validator.localize('en', dict);

const methods = {
  validateBeforeSubmit(e){
    this.$router.push({name: 'Machines',params: {pageno:this.pageno,perpage: this.perPage,order:this.currentSortRoute,search:this.search} })
    /*this.$validator.validateAll().then((result) => {
      if (result) {
          let myForm = document.getElementById('frm');
          let formData = new FormData(myForm);
          const data = {};
          for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
          }

          if(this.$route.params.id!='' && this.$route.params.id!=undefined){
            var metodo = 'PUT';
            Object.assign(data, {id: this.$route.params.id})
          }else{
            var metodo = 'POST';
          }
          axios({url: AUTH_URL+'master/api/v2/company', data: qs.stringify(data), method: metodo ,
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem("user-token"),
              'Accept' : '*\/*',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
              }
            })
            .then(resp => {
              //console.log(resp.data)
            })
            .catch(err => {
              this.error = true;
              console.error(err)
          })
          this.$router.push("/machines");
        return;
      }
    });*/
  }
};

export default {
  name: 'machinesDetail',
  data() {
    return {
      titlePage: 'New Machine',
      currentId: 0,
      detail: [],
      company: [],
      owner: '',
      company_uic: '',
      machine_sn: '',
      machine_type: '',
      device_sn: '',
      device_id: '',
      vpnlink: '',
      vpnlink2: '',
      name: '',
      alarmdb: 'NO DB',
      company_name: '',
      address: '',
      city: '',
      cap:'',
      mint_sn: '',
      state: '',
      notes: '',
      iva: '',
      cf:'',
      owners: [],
      machinetype: [],
      alarms: [],
      serials:[],
      pageno: this.$route.params.pageno,
      perPage: this.$route.params.perpage,
      currentSortRoute: this.$route.params.order,
      search : this.$route.params.search,
    };
  },
  components:{
    Validator
  },
  methods,
  mounted: function mounted(){

  },
  beforeMount: function beforeMount(){
    if(this.$route.params.id!=undefined){
      console.log(this.$route.params.dati)
      this.titlePage = 'Edit Machine';
      this.currentId = this.$route.params.id;
      this.detail = this.$route.params.dati;
      this.owner = this.$route.params.dati.owner;
      this.name = this.$route.params.dati.name;
      this.machine_sn = this.$route.params.dati.machine_sn;
      this.machine_type = this.$route.params.dati.machine_type;
      this.device_sn = this.$route.params.dati.device_sn;
      this.device_id = this.$route.params.dati.device_id;
      this.company_uic = this.$route.params.dati.company_uic;
      this.alarmdb = 'NO DB';
      this.company_name = this.$route.params.dati.company_name;
      this.mint_sn = this.$route.params.dati.mint_sn;
      this.vpnlink = this.$route.params.dati.vpnlink;
      this.vpnlink2 = this.$route.params.dati.vpnlink2;
      this.address = this.$route.params.dati.details.address
      
      this.cap = this.$route.params.dati.details.cap;
      this.city = this.$route.params.dati.details.city;
      this.state = this.$route.params.dati.details.state;
      this.notes = this.$route.params.dati.details.notes;
    }
    axios({url: AUTH_URL+'master/api/v2/machine_groups', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.owners = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
    axios({url: AUTH_URL+'master/api/v2/machinetype', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.machinetype = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
    axios({url: AUTH_URL+'master/api/v2/alarmsdb', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.alarms = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
    axios({url: AUTH_URL+'master/api/v2/serials', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.serials = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
    axios({url: AUTH_URL+'master/api/v2/company', method: 'GET' ,
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.company = resp.data;
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
  }
}
</script>
